import { Box, Flex } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { Card, Spacer } from "src/atoms";
import PrismicLink from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import Renderer from "src/lib/renderer";
import paragraph from "src/lib/renderer/elements/paragraph";
import { CardBadge, CardName, CardPrice } from "./styles";

const PlanCard: FC<Gatsby.PricingTablePlanFragment> = ({
  button_label,
  button_subtitle,
  description,
  plan_badge,
  plan_color,
  plan_name,
  plan_icon,
  tarif,
  tarif_subtitle,
}) => (
  <Card
    as={Flex}
    paddingX="0"
    paddingY="0"
    flexDirection="column"
    borderRadius="16px"
  >
    <Box
      padding="space-32"
      paddingBottom="0"
      bg={`${plan_color}-7`}
      borderTopRadius="16px"
      borderBottomRadius="60% 10%"
      position="relative"
      marginBottom="space-16"
    >
      {plan_badge && <CardBadge color={plan_color!}>{plan_badge}</CardBadge>}

      <Box textAlign="left" position="relative">
        <CardName>{plan_name}</CardName>

        <Flex justifyContent="space-between">
          <CardPrice
            color={plan_color!}
            price={tarif!}
            priceDetails={
              <Renderer
                field={tarif_subtitle}
                overrides={{
                  paragraph: (args) =>
                    paragraph({
                      ...args,
                      overrideProps: { fontSize: "font-14" },
                    }),
                }}
              />
            }
            priceUnit="€"
          />

          <Box position="relative" top="-20px">
            {plan_icon && (
              <GatsbyImage
                alt={plan_icon.alt ?? ""}
                image={plan_icon.gatsbyImageData}
              />
            )}
          </Box>
        </Flex>
      </Box>
    </Box>

    <Box
      paddingY="space-16"
      paddingX="space-32"
      color="text-secondary"
      sx={{ b: { color: "text-primary" } }}
    >
      <Renderer
        field={description}
        overrides={{
          paragraph: (args) =>
            paragraph({
              ...args,
              overrideProps: { fontSize: "font-16" },
            }),
        }}
      />
    </Box>

    <Spacer />

    <Box paddingY="space-24" paddingX="space-32">
      <ShiButton
        as={PrismicLink}
        hasArrow={false}
        size="sm"
        marginBottom="space-16"
        link={{
          type: undefined,
          uid: undefined,
          link_type: "Document",
          url: "/inscription/",
        }}
      >
        {button_label}
      </ShiButton>

      <Renderer
        field={button_subtitle}
        overrides={{
          paragraph: (args) =>
            paragraph({
              ...args,
              overrideProps: {
                fontSize: "font-14",
                marginBottom: "0",
              },
            }),
        }}
      />
    </Box>
  </Card>
);

export default PlanCard;
