import { Box, HStack, VStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";
import { CELL_OVERRIDES, hasOnlySubtitle } from "./utils";

const PlanCell: FC<{
  item: Gatsby.PlanFeatureItemFragment;
  feature_label: Gatsby.Maybe<string>;
  isBigScreen: boolean;
  rowIndex: number;
  cellIndex: number;
  indexOfCardWrapper: number;
}> = ({
  item,
  isBigScreen,
  rowIndex,
  feature_label,

  cellIndex,
  indexOfCardWrapper,
}) => {
  const {
    feature_is_included,
    feature_description,
    feature_icon,
    feature_description_mobile,
    feature_card_image,
  } = item;

  const icon = feature_icon ?? feature_is_included ? "check" : "cross";

  return isBigScreen ? (
    <VStack gridArea={`${rowIndex + 2} / ${cellIndex + 2} / span 1 / span 1`}>
      {feature_card_image?.document?.__typename === "PrismicElementImage" && (
        <Box
          as={GatsbyImage}
          width="75%"
          marginBottom="space-24"
          image={feature_card_image?.document.data?.image_file?.gatsbyImageData}
          alt={feature_card_image?.document.data?.image_file?.alt ?? ""}
        />
      )}

      {hasOnlySubtitle(feature_description?.raw) && (
        <ShiIcon
          name={icon}
          color={feature_is_included ? "text-primary" : "text-light"}
          boxSize="space-16"
        />
      )}

      <Box>
        <Renderer field={feature_description} overrides={CELL_OVERRIDES} />
      </Box>
    </VStack>
  ) : (
    <Box
      gridArea={{
        base: `${rowIndex + 2} / 1 / span 1 / span 1`,
        md: `${rowIndex + 2} / ${cellIndex + 1} / span 1 / span 1`,
      }}
      // Adjustments to handle the card trespassing on its container.
      marginTop={rowIndex === indexOfCardWrapper ? "-space-48" : undefined}
      paddingBottom={
        rowIndex === indexOfCardWrapper - 1 ? "space-48" : undefined
      }
      paddingX={rowIndex >= indexOfCardWrapper ? "space-16" : undefined}
    >
      {feature_card_image?.document?.__typename === "PrismicElementImage" && (
        <Box
          as={GatsbyImage}
          width="75%"
          marginBottom="space-24"
          image={feature_card_image?.document.data?.image_file?.gatsbyImageData}
          alt={feature_card_image?.document.data?.image_file?.alt ?? ""}
        />
      )}

      <HStack
        spacing="space-16"
        textAlign="left"
        color={feature_is_included ? "text-primary" : "text-lighter"}
      >
        <ShiIcon
          name={(icon as any) ?? (feature_is_included ? "tick" : "cross")}
          boxSize="space-16"
        />

        <Box textDecoration={feature_is_included ? undefined : "line-through"}>
          {feature_is_included ? (
            <Renderer
              overrides={CELL_OVERRIDES}
              field={feature_description_mobile}
            />
          ) : (
            feature_label
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default PlanCell;
