import { Tab } from "@chakra-ui/react";
import { FC } from "react";

export const PlansGroupTabTitle: FC = ({ children }) => (
  <Tab
    paddingX={{ base: "space-8", sm: "space-16", md: "space-32" }}
    fontWeight="medium"
    _hover={{
      color: "bg-primary-active",
    }}
    _selected={{
      color: "bg-primary-active",
      borderColor: "bg-primary-active",
    }}
  >
    {children}
  </Tab>
);
