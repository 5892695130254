import { Box, Grid, Text, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import PrismicLink from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import PlanCard from "../PlanCard";
import PlanRow from "../PlanRow";
import { PricingTableProps } from "./types";

const PricingTableDesktop: FC<PricingTableProps> = ({ data }) => {
  const isBigScreen = useBreakpointValue({ base: false, lg: true }) ?? true;

  if (!data?.body || !data.plans) return null;

  const { body, plans } = data;

  const columns = plans.length;
  const rows = body.length + 1;

  /**
   * Yop, that's hardcore.
   * We'll use this to draw a white elevated card starting at this line on mobile devices.
   */
  const indexOfCardWrapper = body.findIndex(
    (row) =>
      row?.__typename === "PrismicPricingTableDataBodyPlanFeature" &&
      row.items?.some((feature) => feature?.feature_card_image?.document)
  );

  return (
    <Grid
      templateColumns={{
        base: `repeat(${columns}, minmax(270px, 290px))`,
        lg: `minmax(200px, 220px) repeat(${columns}, minmax(270px, 290px))`,
      }}
      templateRows={`repeat(${rows + 1}, auto) [last-line]`}
      columnGap={{ base: "space-24", md: "space-32" }}
      rowGap="space-40"
      paddingTop="space-48"
      color="text-primary"
    >
      {/* The first cell of the grid is empty on desktop screens */}
      {isBigScreen && <Box />}

      {plans.map((plan) => {
        if (!plan) return null;

        return <PlanCard key={plan.plan_name} {...plan} />;
      })}

      {body.map((feature, index) => {
        if (!feature) return null;

        switch (feature.__typename) {
          case "PrismicPricingTableDataBodyTitleRow": {
            const isCentered = feature.primary?.placement === "center";
            const hasOffset = isCentered && isBigScreen;

            return (
              <Text
                /**
                 * This title is not really centered,
                 * it's centered across all columns except the first on desktop.
                 */
                gridColumn={hasOffset ? "2 / -1" : "1 / -1"}
                gridRow={`${index + 2} / span 1`}
                fontWeight="medium"
                color="text-light"
                textAlign={isCentered ? "center" : "left"}
              >
                {feature.primary?.row_title}
              </Text>
            );
          }

          case "PrismicPricingTableDataBodyPlanFeature":
            return (
              <PlanRow
                feature={feature}
                rowIndex={index}
                indexOfCardWrapper={indexOfCardWrapper}
                isBigScreen={isBigScreen}
                columns={columns}
              />
            );

          default:
            return null;
        }
      })}

      {Array(columns)
        .fill(undefined)
        ?.map((_, i) => (
          <Box
            key={i}
            gridRow={rows + 1}
            gridColumn={{
              base: i + 1,
              lg: i + 2,
            }}
          >
            <ShiButton
              as={PrismicLink}
              variant="secondary"
              link={{
                type: undefined,
                uid: undefined,
                link_type: "Document",
                url: "/inscription/",
              }}
            >
              Choisir cette offre
            </ShiButton>
          </Box>
        ))}
    </Grid>
  );
};

export default PricingTableDesktop;
