import {
  Box,
  Flex,
  Grid,
  Portal,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, Fragment, useState } from "react";
import PrismicLink from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import { motionVariants } from "../../utils";
import PlanCard from "../PlanCard";
import PlanRow from "../PlanRow";
import { PricingTableProps } from "./types";

const PricingTableMobile: FC<PricingTableProps> = ({
  data,
  columns,
  rows,
  indexOfCardWrapper,
}) => {
  if (!data) throw Error();

  const { plans, body } = data;

  if (!plans || !body) throw Error();

  const [[mobileTabIndex, mobileDirection], setMobileTab] = useState([0, 0]);

  const currentPlan = plans[mobileTabIndex] as Gatsby.PricingTablePlanFragment;

  return (
    <Fragment>
      <Portal>
        <Box position="fixed" bottom="0px" left="0px" width="100vw">
          <Tabs
            backgroundColor="white"
            shadow="sm-light"
            isFitted
            index={mobileTabIndex}
            onChange={(i) =>
              i !== mobileTabIndex && setMobileTab([i, i - mobileTabIndex])
            }
            align="center"
          >
            <TabList width="100%">
              {plans.map((plan, i) => (
                <Tab
                  key={i}
                  _selected={{
                    color: "bg-primary-active",
                    borderColor: "bg-primary-active",
                  }}
                >
                  <Flex flexDirection="column" alignItems="center">
                    <Text as="span" fontWeight="medium">
                      {plan?.plan_name}
                    </Text>
                    {plan?.tarif} €
                  </Flex>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Box>
      </Portal>

      <Box maxWidth="480px" marginX="auto">
        <AnimatePresence
          initial={false}
          exitBeforeEnter
          custom={mobileDirection}
        >
          <motion.div
            custom={mobileDirection}
            initial="before"
            animate="in"
            exit="after"
            variants={motionVariants}
            transition={{ ease: "easeOut", duration: 0.2 }}
            key={plans[mobileTabIndex]?.plan_name}
          >
            <Grid
              templateColumns="1fr"
              templateRows={`repeat(${rows + 1}, auto) [last-line]`}
              rowGap="space-40"
              paddingTop="space-48"
              color="text-primary"
            >
              <PlanCard {...currentPlan} />

              {body.map((feature, index) => {
                if (!feature) return null;

                return (
                  <PlanRow
                    key={feature.id}
                    feature={feature}
                    rowIndex={index}
                    columns={columns}
                    renderOnlyColumn={mobileTabIndex}
                    indexOfCardWrapper={indexOfCardWrapper}
                    isBigScreen={false}
                  />
                );
              })}

              <Box gridRow={rows + 2} gridColumn={1}>
                <ShiButton
                  as={PrismicLink}
                  variant="secondary"
                  link={{
                    type: undefined,
                    uid: undefined,
                    link_type: "Document",
                    url: "/inscription/",
                  }}
                >
                  Choisir cette offre
                </ShiButton>
              </Box>
            </Grid>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Fragment>
  );
};

export default PricingTableMobile;
