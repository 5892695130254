import { Circle, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { ShiIcon } from "src/atoms";

const PlanRowTooltip: FC = ({ children }) => (
  <Tooltip
    label={children}
    backgroundColor="ctaBlue-6"
    color="text-secondary"
    fontWeight="normal"
    padding="space-16"
    borderRadius="md"
    shadow="none"
    placement="bottom-start"
  >
    <Circle
      cursor="help"
      marginRight="space-12"
      size="1.5em"
      backgroundColor="ctaBlue-6"
      _hover={{ backgroundColor: "ctaBlue-4" }}
    >
      <ShiIcon name="info" boxSize=".8em" />
    </Circle>
  </Tooltip>
);

export default PlanRowTooltip;
