import { Badge, Box, Heading, VStack } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import PlanCell from "../PlanCell";
import PlanRowTooltip from "../PlanRowTooltip";

const PlanRow: FC<{
  feature: Gatsby.PlanFeatureFragment;
  rowIndex: number;
  isBigScreen: boolean;
  indexOfCardWrapper: number;
  columns: number;
  renderOnlyColumn?: number;
}> = ({
  feature,
  rowIndex,
  isBigScreen,
  indexOfCardWrapper,
  columns,
  renderOnlyColumn,
}) => {
  if (!feature.primary) return null;

  const { feature_label, feature_tooltip, feature_badge } = feature.primary;

  /**
   * For mobile, we render only one column at a time, targeted by `renderOnlyColumn`.
   */
  const items =
    renderOnlyColumn !== undefined
      ? [feature.items?.[renderOnlyColumn]]
      : feature.items;

  return (
    <Fragment key={feature.primary?.feature_label}>
      {/* If this is the MasterCard row, render its container. */}
      {indexOfCardWrapper === rowIndex &&
        !isBigScreen &&
        Array(columns)
          .fill("")
          .map(
            (_, i) =>
              (renderOnlyColumn === undefined || renderOnlyColumn === i) && (
                <Box
                  key={i}
                  gridArea={`${rowIndex + 2} / ${
                    renderOnlyColumn === i ? 1 : i + 1
                  } / last-line / span 1`}
                  bg="white"
                  borderRadius="xl"
                  shadow="sm-light"
                  marginX="-space-8"
                  marginY="-space-16"
                />
              ),
          )}

      <Heading
        display={{ base: "none", lg: "flex" }}
        gridArea={`${rowIndex + 2} / 1 / span 1 / span 1`}
        as="h3"
        variant="subtitle-small"
        textAlign="left"
      >
        {feature_tooltip && <PlanRowTooltip>{feature_tooltip}</PlanRowTooltip>}

        <VStack alignItems="flex-start">
          <Box>{feature_label}</Box>

          {feature_badge && (
            <Badge color="ctaBlue-1" bg="ctaBlue-5">
              {feature_badge}
            </Badge>
          )}
        </VStack>
      </Heading>

      {items?.map((item, index) => {
        if (!item) return null;

        return (
          <PlanCell
            key={index}
            feature_label={feature_label}
            item={item}
            isBigScreen={isBigScreen}
            rowIndex={rowIndex}
            cellIndex={index}
            indexOfCardWrapper={indexOfCardWrapper}
          />
        );
      })}
    </Fragment>
  );
};

export default PlanRow;
