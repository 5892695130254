import { useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import PricingTableDesktop from "./index.desktop";
import PricingTableMobile from "./index.mobile";

const PricingTable: FC<Gatsby.PricingTableFragment> = ({ data }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!data?.body || !data.plans) return null;

  const { body, plans } = data;

  const columns = plans.length;
  const rows = body.length + 1;

  /**
   * Yop, that's hardcore.
   * We'll use this to draw a white elevated card starting at this line on tablets.
   */
  const indexOfCardWrapper = body.findIndex(
    (row) =>
      row?.__typename === "PrismicPricingTableDataBodyPlanFeature" &&
      row.items?.some((feature) => feature?.feature_card_image?.document)
  );

  return isMobile ? (
    <PricingTableMobile
      data={data}
      indexOfCardWrapper={indexOfCardWrapper}
      columns={columns}
      rows={rows}
    />
  ) : (
    <PricingTableDesktop
      data={data}
      indexOfCardWrapper={indexOfCardWrapper}
      rows={rows}
      columns={columns}
    />
  );
};

export default PricingTable;
