import PricingTable from "./components/PricingTable";
import { TabList, Tabs } from "@chakra-ui/react";
import { FC, useState } from "react";
import { motionVariants } from "./utils";
import { PlansGroupTabTitle } from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import { clamp } from "ramda";

const PagePricingPlans: FC<Gatsby.PagePricingPlansFragment> = ({
  primary,
  items,
}) => {
  if (!items || !primary) throw Error();

  const defaultTabIndex = primary.default_tab_number
    ? clamp(0, items.length, primary.default_tab_number - 1)
    : 0;

  const [[tabIndex, direction], setTab] = useState([defaultTabIndex, 0]);

  const currentPlan = items[tabIndex]?.table?.document?.data;

  return (
    <Tabs
      index={tabIndex}
      onChange={(i) => i !== tabIndex && setTab([i, i - tabIndex])}
      align="center"
    >
      <TabList width="fit-content">
        {items.map((tab) => {
          const title = tab?.table?.document?.data?.title;

          return <PlansGroupTabTitle key={title}>{title}</PlansGroupTabTitle>;
        })}
      </TabList>

      <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
        {/* We declare a single node. */}
        {/* When its key changes, AnimatePresence exits it, then mounts another. */}
        <motion.div
          key={`plan-${currentPlan?.title}`}
          custom={direction}
          initial="before"
          animate="in"
          exit="after"
          variants={motionVariants}
          transition={{ ease: "easeOut", duration: 0.2 }}
        >
          <PricingTable data={currentPlan} />
        </motion.div>
      </AnimatePresence>
    </Tabs>
  );
};

export default PagePricingPlans;
